import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Paragraph3 } from './reusables/textElements';
import { breakpoints, colors } from '../lib/constants';
import ContentWrapper from './reusables/ContentWrapper';
import Logo from './Logo';
import MailchimpForm from './MailchimpForm';
import SocialIcons from './SocialIcons';
import WaveContainer from './WaveContainer';

const Container = styled.div({});

const Items = styled.div(({ isCenterAligned }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  columnGap: 20,
  marginBottom: 80,
  textAlign: isCenterAligned ? 'center' : 'left',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media (max-width: 450px)`]: {
    display: 'block',
  },
}));

const LogoContainer = styled.div({
  textAlign: 'center',
  marginBottom: 90,
  [`@media (max-width: 450px)`]: {
    marginTop: 30,
    marginBottom: 40,
  },
});

const Item = styled.div({
  marginBottom: 30,
});

const Anchor = styled.a({
  color: 'inherit',
  textDecoration: 'underline',
});

const SocialIconsWrapper = styled.div({
  width: 150,
  margin: '0 auto',
});

export function ContactItems({
  isSocialVisible = false,
  isCenterAligned = true,
  ...rest
}) {
  return (
    <Items {...rest} isCenterAligned={isCenterAligned}>
      <Item>
        <Paragraph3 isBold>Beteendelabbet</Paragraph3>
        <Paragraph3>Svartmangatan 9</Paragraph3>
        <Paragraph3>111 29 Stockholm</Paragraph3>
        <Paragraph3>
          <Anchor as={Link} to="/policy">
            Integritetspolicy
          </Anchor>
        </Paragraph3>
      </Item>
      <Item>
        <Paragraph3 isBold>Uppdragsförfrågningar</Paragraph3>
        <Paragraph3>Kontakta Ida Lemoine</Paragraph3>
        <Paragraph3>
          <Anchor href="mailto:ida@beteendelabbet.se ">
            ida@beteendelabbet.se
          </Anchor>
        </Paragraph3>
        <Paragraph3>
          <Anchor href="tel:+46763373666">076 337 36 66</Anchor>
        </Paragraph3>
      </Item>
      <Item>
        <Paragraph3 isBold>Säg hej</Paragraph3>
        <Paragraph3>Kontakta oss</Paragraph3>
        <Paragraph3>
          <Anchor href="mailto:hej@beteendelabbet.se">
            hej@beteendelabbet.se
          </Anchor>
        </Paragraph3>
        <Paragraph3>
        </Paragraph3>
      </Item>
      {isSocialVisible && (
        <Item>
          <div css={{ marginBottom: 10 }}>Sociala medier</div>
          <SocialIconsWrapper>
            <SocialIcons />
          </SocialIconsWrapper>
        </Item>
      )}
    </Items>
  );
}

export default ({ children, waveColor = colors.baseBlue, mirrored = true }) => (
  <Container>
    <WaveContainer fill={waveColor} mirrored={mirrored}>
      {children || (
        <ContentWrapper>
          <MailchimpForm />
        </ContentWrapper>
      )}
      <WaveContainer fill="#000000" mirrored>
        <ContentWrapper css={{ color: '#fff' }}>
          <LogoContainer>
            <Logo fill="#fff" />
          </LogoContainer>
          <ContactItems isSocialVisible={true} />
        </ContentWrapper>
      </WaveContainer>
    </WaveContainer>
  </Container>
);
