import styled from 'styled-components';

import { colors } from '../../lib/constants';

export default styled.button(({ variant }) => ({
  cursor: 'pointer',
  borderRadius: 28,
  backgroundColor: 'transparent',
  border: `2px solid ${colors.baseBlue}`,
  textTransform: 'uppercase',
  textAlign: 'center',
  height: 56,
  minWidth: 270,
  color: colors.baseBlue,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  ...(variant === 'filled' && {
    border: 'none',
    backgroundColor: colors.baseBlue,
    color: '#fff',
  }),
  ...(variant === 'inverted' && {
    border: 'none',
    backgroundColor: '#fff',
  }),
}));
