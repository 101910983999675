import React, { useState } from 'react';
import jsonp from 'jsonp';
import styled from 'styled-components';

import { breakpoints, colors, fonts } from '../lib/constants';
import Button from './reusables/Button';

const Heading = styled.h3({
  fontSize: 48,
  lineHeight: 1.1,
  textTransform: 'uppercase',
  margin: '30px 0 10px',
  color: '#fff',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: 36,
    maxWidth: 300,
  },
});

const Description = styled.div({
  fontFamily: fonts.bold,
  color: '#fff',
  marginBottom: 30,
});

const Input = styled.input({
  borderRadius: 28,
  backgroundColor: 'transparent',
  border: `2px solid #fff`,
  textAlign: 'center',
  height: 56,
  width: '100%',
  maxWidth: 270,
  marginBottom: 10,
  color: '#fff',
  outline: 'none',
  '::placeholder': { color: 'rgba(255, 255, 255, 0.5)' },
  '&:focus::placeholder ': { opacity: 0, transition: 'opacity 300ms' },
});

const Form = styled.form({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

function MailchimpForm() {
  const [phase, setPhase] = useState('initial');
  const [emailAddress, setEmailAddress] = useState('');
  return (
    <Form
      target="_blank"
      action=""
      onSubmit={e => {
        e.preventDefault();
        setPhase('submitting');
        const url = `https://beteendelabbet.us17.list-manage.com/subscribe/post-json?u=4b16bcb0f9e4af898446b0f47&id=0a0f0e3cd0&EMAIL=${encodeURIComponent(
          emailAddress
        )}`;
        jsonp(url, { param: 'c' }, (e, data) => {
          if (e) {
            setPhase('error');
            return;
          }
          setPhase('done');
        });
      }}
    >
      <Heading>1400+ personer gör det</Heading>
      <Description>
        {phase === 'done'
          ? 'Tack för din anmälan!'
          : phase === 'error'
          ? 'Ett fel inträffade'
          : 'Följ Beteendebrevet du också!'}
      </Description>
      <Input
        aria-label="E-postadress"
        type="email"
        name="email"
        required
        value={emailAddress}
        onChange={e => setEmailAddress(e.target.value)}
        placeholder="Din e-postadress"
      />
      <Button
        disabled={phase === 'submitting'}
        type="submit"
        variant="inverted"
      >
        {phase === 'submitting' ? 'Anmäler...' : 'Anmäl mig'}
      </Button>
    </Form>
  );
}

export default MailchimpForm;
