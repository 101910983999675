import React, { useState } from 'react';
import styled from 'styled-components';

const StyledForm = styled.form({
  color: '#666',
  position: 'relative',
  width: '100%',
  maxWidth: 350,
  transition: 'width 300ms',
  display: 'inline-block',
  '&:has(input)': { width: '70%' },
  '&:has(input:focus)': { width: '100%' },
});

const StyledInput = styled.input({
  borderRadius: 12,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: `1px solid #ccc`,
  padding: '5px 20px 5px 10px',
  outline: 'none',
  width: '100%',
  '&::placeholder ': { transition: 'opacity 300ms' },
  '&:focus::placeholder ': { opacity: 0 },
});

const StyledSvg = styled.svg({
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 20,
  height: 20,
  fill: 'currentColor',
});

export default function SearchForm({ initialValue, large = false }) {
  const [value, setValue] = useState(initialValue);
  return (
    <StyledForm action="/search" method="GET">
      <StyledInput
        type="text"
        placeholder="Sök"
        name="q"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={(e) => e.target.select()}
        css={{
          padding: large ? '10px 25px 10px 15px' : undefined,
          fontSize: large ? 18 : undefined,
          borderRadius: large ? 16 : undefined,
        }}
      />
      <StyledSvg height="20" width="20" viewBox="0 0 48 48">
        <path d="M39.8 41.95 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L42 39.75Zm-20.95-13.4q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z" />
      </StyledSvg>
    </StyledForm>
  );
}
