import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../lib/constants';
import Button from './reusables/Button';
import ContentWrapper from './reusables/ContentWrapper';
import Logo from './Logo';
import SearchForm from './SearchForm';
import hamburgerIcon from '../assets/hamburgerIcon.svg';
import closeIcon from '../assets/closeIcon.svg';

const MOBILE_PX_FROM_BOTTOM = 50;

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 75,
});

const StyledLink = styled(Link).attrs({
  activeClassName: 'link-active',
})({
  color: 'inherit',
  fontSize: 16,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'none',
  },
  [`@media (max-width: ${breakpoints.oneColumn + 100}px)`]: {
    fontSize: 14,
  },
});

const SearchContainer = styled.div({
  [`@media (max-width: 900px)`]: {
    width: 130,
  },
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'none',
  },
});

const StyledLogo = styled(Logo)({
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fill: '#fff',
  },
});

const Col = styled.div({});

const MenuButton = styled.button({
  zIndex: '1',
  border: `2px solid #333`,
  borderRadius: '50%',
  height: 68,
  width: 68,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  bottom: 'env(safe-area-inset-bottom)',
  marginBottom: MOBILE_PX_FROM_BOTTOM,
  cursor: 'pointer',
  right: 20,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'flex',
  },
});

const MobileMenu = styled.div(({ visible }) => ({
  position: 'fixed',
  textAlign: 'center',
  zIndex: '2',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#000',
  opacity: visible ? '1' : '0',
  pointerEvents: visible ? 'inherit' : 'none',
  transition: 'opacity 200ms',
  paddingBottom: 100, // room for close button
}));

const MobileLink = styled(Link).attrs({
  activeClassName: 'link-active',
})({
  color: '#fff',
  fontSize: 30,
});

export default function TopNav({ white }) {
  const [isMenuVisible, setMenuVisible] = useState(false);
  return (
    <ContentWrapper css={{ position: 'relative' }}>
      <Container>
        <Col css={{ flex: 2 }}>
          <StyledLink css={{ marginRight: 15 }} to="/cases">
            Case
          </StyledLink>
          <StyledLink css={{ marginRight: 15 }} to="/about">
            Våra tjänster
          </StyledLink>
          <StyledLink to="/latest">Blogg & Nyheter</StyledLink>
        </Col>
        <Col css={{ textAlign: 'center', flex: 1 }}>
          <Link to="/" aria-label="Beteendelabbet logotyp">
            <Logo fill={white ? '#fff' : colors.baseBlack} />
          </Link>
        </Col>
        <Col
          css={{
            textAlign: 'right',
            flex: 2,
            gap: 15,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <SearchContainer>
            <SearchForm />
          </SearchContainer>
          <StyledLink to="/contact">Kontakt</StyledLink>
        </Col>
      </Container>
      <MenuButton onClick={() => setMenuVisible(true)} aria-label="Öppna meny">
        <img
          alt="Meny-ikon"
          src={hamburgerIcon}
          css={{ width: 31, height: 22 }}
        />
      </MenuButton>
      <MobileMenu visible={isMenuVisible}>
        <ContentWrapper>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '100%',
              height: 'calc(100vh - 20px)',
              gap: 30,
            }}
          >
            <div css={{ marginTop: 10 }}>
              <Link to="/" aria-label="Beteendelabbet logotyp">
                <Logo fill="#fff" />
              </Link>
            </div>
            <div>
              <SearchForm large />
            </div>
            <div>
              <MobileLink to="/cases">Case</MobileLink>
            </div>
            <div>
              <MobileLink to="/about">Våra tjänster</MobileLink>
            </div>
            <div>
              <MobileLink to="/latest">Blogg & Nyheter</MobileLink>
            </div>
            <div>
              <MobileLink to="/contact">Kontakta oss</MobileLink>
            </div>
          </div>
        </ContentWrapper>
        <MenuButton
          onClick={() => setMenuVisible(false)}
          aria-label="Öppna meny"
        >
          <img
            alt="Stäng meny"
            src={closeIcon}
            css={{ width: 32, height: 32 }}
          />
        </MenuButton>
      </MobileMenu>
    </ContentWrapper>
  );
}
