import React from 'react';

export default ({ height = 50, fill = '#000000'}) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 320.852417 112.1011658"
    height={height}
    fill={fill}
  >
    <g>
      <g>
        <path
          d="M96.9673386,59.7625389L80.777504,112.1011658h9.7002106l3.8083572-13.119339h14.9468536l3.7855377,13.119339h9.7002106
			l-16.1204758-52.3386269H96.9673386z M96.8194962,90.2585754l4.9637299-17.0973282l4.9327011,17.0973282H96.8194962z"
        />
        <path
          d="M159.394043,74.975647c0-4.5361633-1.6043854-8.2347794-4.8843384-11.0263596
			c-3.2105865-2.7911263-7.1184082-4.1867485-11.7937469-4.1867485h-17.0979004v52.3386269h17.0979004
			c4.6753387,0,8.5831604-1.3956223,11.7937469-4.1872101c3.279953-2.7912369,4.8843384-6.4897308,4.8843384-11.0259018
			c0-4.4662323-1.6746521-8.0949173-5.0942383-10.8864975C157.7193909,83.3496017,159.394043,79.6511002,159.394043,74.975647z
			 M142.924942,103.3779068h-8.0949249V90.2585754h8.0949249c4.187088,0,7.2580414,2.442276,7.2580414,6.6294785
			S147.11203,103.3779068,142.924942,103.3779068z M142.924942,81.5353165h-8.0949249V68.4854507h8.0949249
			c4.187088,0,7.2580414,2.3029938,7.2580414,6.4901962C150.1829834,79.1623993,147.11203,81.5353165,142.924942,81.5353165z"
        />
        <path
          d="M197.3543701,74.975647c0-4.5361633-1.6052856-8.2347794-4.8843231-11.0263596
			c-3.2105865-2.7911263-7.1184235-4.1867485-11.7937622-4.1867485h-17.0978851v52.3386269h17.0978851
			c4.6753387,0,8.5831757-1.3956223,11.7937622-4.1872101c3.2790375-2.7912369,4.8843231-6.4897308,4.8843231-11.0259018
			c0-4.4662323-1.6746521-8.0949173-5.094223-10.8864975C195.679718,83.3496017,197.3543701,79.6511002,197.3543701,74.975647z
			 M180.8852692,103.3779068h-8.0949097V90.2585754h8.0949097c4.187088,0,7.2580566,2.442276,7.2580566,6.6294785
			S185.0723572,103.3779068,180.8852692,103.3779068z M180.8852692,81.5353165h-8.0949097V68.4854507h8.0949097
			c4.187088,0,7.2580566,2.3029938,7.2580566,6.4901962C188.1433258,79.1623993,185.0723572,81.5353165,180.8852692,81.5353165z"
        />
        <polygon
          points="201.5378113,112.1011658 234.1976624,112.1011658 234.1976624,103.3779068 210.7497711,103.3779068
			210.7497711,90.2585754 234.1976624,90.2585754 234.1976624,81.5353165 210.7497711,81.5353165 210.7497711,68.4854507
			234.1976624,68.4854507 234.1976624,59.7625389 201.5378113,59.7625389 		"
        />
        <polygon
          points="238.382019,68.4854507 252.1999512,68.4854507 252.1999512,112.1011658 261.4110107,112.1011658
			261.4110107,68.4854507 275.2289429,68.4854507 275.2289429,59.7625389 238.382019,59.7625389 		"
        />
        <polygon
          points="55.113781,59.7625389 45.9027328,59.7625389 45.6234703,112.1011658 78.7387161,112.1011658
			78.7387161,103.3782501 55.113781,103.3782501 		"
        />
      </g>
      <g>
        <polygon
          points="227.2780762,37.6142235 201.7366486,0.7676255 192.5256042,0.7676255 192.5256042,53.1063614
			201.4573975,53.1063614 201.4573975,16.2598801 227.0690918,53.1063614 236.2810516,53.1063614 236.2810516,0.7676255
			227.2780762,0.7676255 		"
        />
        <path
          d="M276.124115,20.028307c-3.1403198-0.2791462-4.7456055-1.4655495-4.7456055-3.4893932
			c0-0.9070282,0.4891663-2.3029909,1.3963013-4.186862c0.9765015-1.8842134,1.4656677-3.4893932,1.4656677-4.9548292
			C274.2404785,3.2100186,270.7506104,0,265.7960205,0c-1.3260193,0-3.2799377,0.5580657-5.7221069,1.6746534
			c-2.4430847,1.1165878-4.3969879,1.6749958-5.8617401,1.6749958c-2.0935516,0-3.1412354-0.837441-3.1412354-2.5820236h-9.2110443
			v52.3387375h17.1672363c4.3960876,0,1.3953857-5.7224541,4.8149719-5.7224541
			c1.6053162,0,1.0467834,2.3028755,4.9546204,2.3028755c2.0241699,0,4.0474548-1.465435,4.1177063-3.3496475
			c0-0.0698166,0-0.4882507-0.0702515-1.3956184c-0.0693665-0.9073715-0.0693665-1.6749992,0-2.2330627
			c0.0702515-0.5585213,0.2792664-1.046772,0.5585022-1.5353661c1.0467834-1.7444687,4.3970032-3.6986122,6.9085388-5.0243034
			c2.5827026-1.3959618,5.0942078-3.9779854,5.0942078-7.0487156C285.405426,23.4477711,282.5443726,20.656189,276.124115,20.028307
			z M272.0072937,37.3348465c-5.4437561,0-3.0709534-4.3964195-8.5137939-4.3964195
			c-2.7223511,0-3.6988525,3.1404305-3.6285706,6.280632c0,3.0706177-1.1170654,6.2110481-4.4663544,6.2110481
			c-2.0241852,0-3.3502197-1.1165886-3.8384705-3.1404305c-0.5585327-2.1632462-0.4891663-3.0706177-0.4891663-5.0942307V14.8642607
			c0-3.6986113,2.7223358-6.2111626,6.07164-6.2111626c5.3735046,0,6.7688904,3.6989536,6.7688904,9.8398447
			c0,3.4195786,0.977417,5.2338619,5.4437561,5.5130081c5.5131226,0.2790337,7.6760254,3.5592098,7.6760254,6.6992989
			C277.03125,34.8226395,274.5197449,37.3348465,272.0072937,37.3348465z"
        />
        <polygon
          points="320.852417,9.490653 320.852417,0.7676255 288.1934814,0.7676255 288.1934814,53.1063614 320.852417,53.1063614
			320.852417,44.3831062 297.404541,44.3831062 297.404541,31.2637711 320.852417,31.2637711 320.852417,22.5404015
			297.404541,22.5404015 297.404541,9.490653 		"
        />
        <polygon
          points="91.2588043,53.1063614 100.4707718,53.1063614 100.4707718,9.490653 114.2878036,9.490653 114.2878036,0.7676255
			77.4417725,0.7676255 77.4417725,9.490653 91.2588043,9.490653 		"
        />
        <polygon
          points="151.1520844,44.3831062 127.7041931,44.3831062 127.7041931,31.2637711 151.1520844,31.2637711
			151.1520844,22.5404015 127.7041931,22.5404015 127.7041931,9.490653 151.1520844,9.490653 151.1520844,0.7676255
			118.4922333,0.7676255 118.4922333,53.1063614 151.1520844,53.1063614 		"
        />
        <polygon
          points="188.029129,44.3831062 164.5821533,44.3831062 164.5821533,31.2637711 188.029129,31.2637711
			188.029129,22.5404015 164.5821533,22.5404015 164.5821533,9.490653 188.029129,9.490653 188.029129,0.7676255
			155.3701935,0.7676255 155.3701935,53.1063614 188.029129,53.1063614 		"
        />
        <polygon
          points="73.0329208,44.3831062 49.5850334,44.3831062 49.5850334,31.2637711 73.0329208,31.2637711
			73.0329208,22.5404015 49.5850334,22.5404015 49.5850334,9.490653 73.0329208,9.490653 73.0329208,0.7676255
			40.3730698,0.7676255 40.3730698,53.1063614 73.0329208,53.1063614 		"
        />
        <g>
          <path
            d="M9,0.7676255c0,0,1.1700392,2.1734986,4.3271503,2.1734986c4.9304962,0,6.7473297-2.1734607,11.0115719-2.1734607
				c2.7106457,0,5.004158,1.1121135,6.8819542,3.4056268c1.8763847,2.2242274,2.7799301,4.9348717,2.7799301,8.0626469
				c0,4.8968678-3.7939014,8.4215374-7.5762291,9.1140385c-2.2785301,0.4171791-3.4542923,1.6833763-3.0748577,4.2313232
				c0.2785587,2.0156631,0.9199181,2.9192104,3.7691345,3.1277752c4.3098812,0.3478451,9.6618824,1.5985317,9.5925961,9.3140392
				c0,2.5720711-0.6956902,4.7263069-2.1549416,6.534111c-1.3899651,1.8070946-3.087532,2.1869621-5.2134285,2.6406517
				c-1.3223476,0.2821999-1.8618603,1.2263489-3.1277752,0.9578781c-1.4930248-0.3166351-1.3979263-1.181469-3.1277752-1.112114
				c-1.7377949,0.0696754-1.6241989,2.3895721-2.3627987,3.6570206s-2.2687244,1.7608604-4.1549416,2.0884972
				c-1.8862171,0.3276329-4.1277752,0.3171272-7.4634085,0.3171272H0V0.7676255H9z M19.0560074,5.5632553
				c-8.6220541,0-9.9498262,7.7422848-9.9498262,10.912571v6.7426739h7.656312c2.1549416,0,3.2374916-0.2588863,3.7448902-1.0562286
				c0.3484364-0.5475407,0.8000164-1.6551228,0.0350399-2.55867c-0.7635612-0.9035492-1.1114063-1.9456692-0.9035473-3.1277752
				c0.2785587-1.6678171,1.4296665-2.4452047,2.5199165-2.9799309c1.4685631-0.720274,2.1549377-2.280158,1.3456688-4.8048649
				C22.8650608,6.6962662,21.2802334,5.5632553,19.0560074,5.5632553z M20.7245312,31.8368492h-11.61835v9.1054764
				c0,3.1977654-0.0735254,4.907299,0.8328476,6.0476913c0.906374,1.1403923,3.3904486,1.1321716,3.3904486,1.1321716
				s1.3648748,0.0020561,2.2938948-0.1522217c1.7159338-0.2849579,2.4747591-2.090229,3.181241-3.8455353
				c0.6026077-1.4972191,1.2438755-2.2864571,2.754179-2.8927536c1.5103054-0.6062927,2.4547062-0.2204628,3.5449066-0.7221451
				c1.0902004-0.5016861,1.7378101-2.8335571,1.8070965-4.5713654
				C26.9800816,32.9489632,24.1308651,31.8368492,20.7245312,31.8368492z"
          />
        </g>
      </g>
    </g>
  </svg>
);
