import styled from 'styled-components';

import { breakpoints, gutters } from '../../lib/constants';

const ContentWrapper = styled.div({
  padding: `${gutters.vertical}px ${gutters.horizontal}px 50px`,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    padding: `${gutters.smallVertical}px ${gutters.smallHorizontal}px`,
  },
});

export default ContentWrapper;
