import styled from 'styled-components';

import { fonts } from '../../lib/constants';
import { breakpoints } from '../../lib/constants';

export const PrimaryHeading = styled.h1({
  fontSize: 80,
  lineHeight: 1.1,
  margin: 0,
  textTransform: 'uppercase',
  fontFamily: fonts.bold,
  marginLeft: -4, // adjust for font
  [`@media (max-width: 1100px)`]: {
    fontSize: 50,
    marginBottom: 15,
    marginLeft: -3,
  },
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: 36,
    marginBottom: 15,
    marginLeft: -1,
  },
});
export const SecondaryHeading = styled.h2({
  fontSize: 48,
  lineHeight: 1.1,
  textTransform: 'uppercase',
  margin: 0,
  fontFamily: fonts.bold,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: 36,
    marginBottom: 15,
  },
});
export const TertiaryHeading = styled.h3({
  margin: 0,
  fontSize: 24,
  fontFamily: fonts.bold,
});

export const Paragraph1 = styled.div({
  lineHeight: 1.5,
  fontSize: 18,
});

export const Paragraph2 = styled.div(({ isBold }) => ({
  lineHeight: 1.25,
  fontSize: 16,
  fontFamily: isBold ? fonts.bold : 'inherit',
}));

export const Paragraph3 = styled.div(({ isBold }) => ({
  lineHeight: 1.3,
  fontSize: 14,
  fontFamily: isBold ? fonts.bold : 'inherit',
}));
