import React from 'react';
import styled from 'styled-components';

import facebookIcon from '../assets/facebookIcon.svg';
import instagramIcon from '../assets/instagramIcon.svg';
import linkedInIcon from '../assets/linkedInIcon.svg';
import twitterIcon from '../assets/twitterIcon.svg';

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  columnGap: 2,
});

export default ({ inverted, columnGap = 2 }) => (
  <Container
    css={{
      columnGap,
      img: { filter: inverted ? 'invert(80%)' : 'invert(0%)' },
    }}
  >
    <a
      href="https://twitter.com/beteendelabbet"
      aria-label="Twitter"
      target="_blank"
    >
      <img alt="Twitter-ikon" src={twitterIcon} />
    </a>
    <a
      href="https://www.linkedin.com/company/beteendelabbet"
      aria-label="LinkedIn"
      target="_blank"
    >
      <img alt="LinkedIn-ikon" src={linkedInIcon} />
    </a>
    <a
      href="https://www.instagram.com/beteendelabbet/"
      aria-label="Instagram"
      target="_blank"
    >
      <img alt="Instagram-ikon" src={instagramIcon} />
    </a>
    <a
      href="https://www.facebook.com/Beteendelabbet-305885859612547/"
      target="_blank"
      aria-label="Facebook"
    >
      <img alt="Facebook-ikon" src={facebookIcon} />
    </a>
  </Container>
);
