import { Helmet } from 'react-helmet';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { colors, fonts } from '../lib/constants';
import * as base64Fonts from '../assets/base64Fonts';
import boldWoff from '../assets/GT-Walsheim-Bold.woff';
import favicon from '../assets/favicon.png';
import regularWoff from '../assets/GT-Walsheim-Regular.woff';
import heroImage from '../assets/hero.jpg';
import Footer from './Footer';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}
  @font-face {
    font-family: "${fonts.regular}";
    src: url("${base64Fonts.regular}") format("woff2"),
         url("${regularWoff}") format("woff");
  }
  @font-face {
    font-family: "${fonts.bold}";
    font-weight: bold;
    src: url("${base64Fonts.bold}") format("woff2"),
         url("${boldWoff}") format("woff");
  }
  * {
    box-sizing: border-box;
  }
  body {
    font-family: "${fonts.regular}", sans-serif;
    overflow-y: scroll;
    transition: background-color 200ms;
    font-size: 16px;
    line-height: 1.25;
  }
  body.bg-white {
    background-color: #ffffff;
  }
  body.bg-blue {
    background-color: ${colors.baseBlue};
  }

  h1,h2,h3,h4 {
    font-family: "${fonts.bold}", sans-serif;
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: ${colors.baseBlack}
  }
  b {
    font-family: ${fonts.bold};
  }
  a.link-active {
    font-family: ${fonts.bold};
  }
  a.link-active::before {
    content: '→ ';
  }
  p a {
    text-decoration: underline;
  }

  ul {
    padding-left: 15px;
  }
`;

const Container = styled.div({
  maxWidth: 1440,
  margin: '0 auto',
});

function AppChrome({
  title,
  children,
  og = {},
  backgroundColor = '#fff',
  footer,
  ...rest
}) {
  const fullTitle = [title, 'Beteendelabbet'].filter(Boolean).join(' - ');

  const bodyClass =
    backgroundColor === colors.baseBlue ? 'bg-blue' : 'bg-white';

  const imgPath = og.image || heroImage;
  const fullImgPath = /^http/.test(imgPath)
    ? imgPath
    : /^\/\//.test(imgPath)
    ? `https:${imgPath}`
    : `https://beteendelabbet.se${imgPath}`;

  return (
    <div>
      <Container {...rest}>
        <Helmet title={fullTitle} bodyAttributes={{ class: bodyClass }}>
          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
          <meta property="og:title" content={og.title || 'Beteendelabbet'} />
          <meta property="og:description" content={og.description} />
          <meta property="og:type" content={og.type || 'website'} />
          <meta property="og:image" content={fullImgPath} />
          <meta name="Description" content={og.description} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@beteendelabbet" />
        </Helmet>
        <GlobalStyles />
        {children}
      </Container>
      {footer || <Footer />}
    </div>
  );
}

export default AppChrome;
