export const colors = {
  baseBlue: '#030072',
  basePink: '#ff8fdf',
  baseGrey: '#b8c1b9',
  baseRed: '#e74c4b',
  baseBlack: '#202020',
};

export const fonts = {
  regular: 'GT-Walsheim',
  bold: 'GT-Walsheim-Bold',
};

export const breakpoints = {
  oneColumn: 800,
};

export const gutters = {
  vertical: 20,
  horizontal: 50,
  smallVertical: 10,
  smallHorizontal: 10,
};
