import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../lib/constants';

const Container = styled.div({
  position: 'relative',
  minHeight: 120,
  paddingTop: '20%',
  marginTop: 70,
});

const Content = styled.div({
  position: 'relative',
});

const Svg = styled.svg(({ mirrored }) => ({
  width: '180%',
  transform: 'translateX(-20%)',
  ...(!mirrored && {
    transform: 'scale(-1, 1) translateX(26%)',
  }),
}));

export default ({ fill, children, bleedTop, mirrored }) => (
  <Container>
    <div
      css={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
      }}
    >
      <Svg mirrored={mirrored} fill={fill} viewBox="0 0 2578 1275">
        <path
          fill-rule="evenodd"
          d="M0 232.163c293.5-58.208 415.222-164.03 620.429-164.03 80.083 0 295.31 58.465 470.507 53.565 105.108 0 225.222-24.356 355.355-68.17 130.133-43.86 235.266-63.365 320.335-48.713 83.999 11.678 233.915 80.705 400.613 169.14 117.261 62.208 344.587 57.253 410.761 58.208V1275.11H0V232.163z"
        />
      </Svg>
    </div>
    <Content css={{ backgroundColor: fill }}>{children}</Content>
  </Container>
);
